<template>
  <div>
    <base-material-card
      :color="inventoryFrameColor"
      class="px-4 py-2"
    >
      <template v-slot:heading>
        <div class="display-2 font-weight-bold">
          {{ $t('titleInventoryAvailableWaste') }}
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="inventoryHeaders"
          :items="inventoryProducts"
          item-key="partnerId"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template v-slot:item.availableQuantity="{ item }">
            <span class="font-weight-bold">
              {{ item.availableQuantity }} {{ item.unitText }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay :processing="modelProcessing" />
  </div>
</template>
<script>
  import i18n from '@/i18n'
  import { mapFields } from 'vuex-map-fields'
  import inventoryAPI from '@/services/inventory'
  function initialState () {
    return {
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      partnerName: '',
      inventoryHeaders: [
        {
          text: i18n.t('col-inventory-Partner'),
          align: 'start',
          value: 'partnerName',
          sortable: false,
          class: 'inventorycol display-1',
        },
        {
          text: i18n.t('col-inventory-Quantity'),
          align: 'end',
          value: 'availableQuantity',
          sortable: false,
          class: 'inventorycol display-1',
          width: '25%',
        },
      ],
      inventoryProducts: [],
      inventoryFrameColor: '#827717',
    }
  }
  export default {
    name: 'InventoryComponent',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    props: {
      dataType: {
        type: Number,
        default: 1,
      },
      productId: {
        type: Number,
        default: 0,
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.inventoryProducts = []
      if (this.productId !== 0) {
        this.modelProcessing = true
        inventoryAPI
          .getInventory(this.dataType, this.productId)
          .then((response) => {
            this.inventoryProducts = response.data
            this.modelProcessing = false
          })
          .catch((error) => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      }
    },
    methods: {},
  }
</script>
